import { UploadToPhotosForm } from './VhsUpload';
import styled from '@emotion/styled';



const StyledActionsContainer = styled.div`
  margin-top: 20px;
  padding: 16px;
  border-top: 1px solid #f0f0f0;
`;


const ProjectActions = ({ user, project }) => {
  return (
    <StyledActionsContainer>
      <h3 className="text-lg font-medium mb-4">Actions</h3>
      <div className="space-y-4">
        <UploadToPhotosForm user={user} project={project} />
      </div>
    </StyledActionsContainer>
  );
};

export default ProjectActions;