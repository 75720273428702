import { post,postFormData, get, put, del } from 'utils/sdk';


export const splitAndUploadScenes = ({user,project,rows, blob, aspectRatio} ) => {

  const headers = {
    Authorization: "idToken",
  };

  
  const data = new FormData();
  data.append('rows', JSON.stringify(rows));
  data.append('video', blob);
  data.append('aspectRatio', JSON.stringify(aspectRatio));

  return postFormData(`users/${user.id}/projects/${project.id}/videos/upload`,data, { headers });
};

export const uploadVideosFromDrive = ({user,project,data} ) => {

  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };

  return post(`users/${user.id}/projects/${project.id}/upload_project_videos`,data, { headers }); 
};


export const createProject = ({user,data} ) => {

  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };

  return post(`users/${user.id}/projects/create`,data, { headers });
};


export const acceptInvitation = ({user,data} ) => {

  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };

  return post(`users/${user.id}/accept_invitation`,data, { headers });
};


export const approveInvitation = ({projectId,data} ) => {

  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };

  return put(`projects/${projectId}/invite/approve`,data, { headers });
};

export const unapproveInvitation = ({projectId,data} ) => {

  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };

  return put(`projects/${projectId}/invite/unapprove`,data, { headers });
};


export const sendInvitation = ({user,project_id,data} ) => {

  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };

  return post(`projects/${project_id}/invite`,data, { headers });
};


export const uploadToPhotos = ({ user, project_id, data }) => {
  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };

  const url = `users/${user.id}/projects/${project_id}/upload_to_google_photos`;

  return post(url, data, { headers });
};


export const updateProject = ({user,project_id,data} ) => {

  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };

  return put(`users/${user.id}/projects/${project_id}`,data, { headers });
};


export const deleteProject = ({user,project_id} ) => {

  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };

  return del(`users/${user.id}/projects/${project_id}`,{ headers });
};

export const getVideoDetails = ({video_id} ) => {
  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };
  return get(`videos/${video_id}`, { headers });
};


export const updateVideo = ({video_id, data, userId} ) => {
  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };
  const queryParams = new URLSearchParams({ userId });
  return put(`videos/${video_id}?${queryParams.toString()}`,data, { headers });
};

export const updateScene = ({ scene_id, data, userId }) => {
  const headers = {
    Authorization: 'idToken',
    'Content-Type': 'application/json',
  };
  const queryParams = new URLSearchParams({ userId });

  return put(`scenes/${scene_id}?${queryParams.toString()}`, data, { headers });
};

export const uploadScene = ({ newSceneData, video_id, userId }) => {
  const headers = {
    Authorization: 'idToken',

  };


  const data = new FormData();
  data.append('scene_name', newSceneData.scene_name);
  data.append('scene_date', newSceneData.scene_date);
  data.append('video_time', newSceneData.video_time);
  data.append('video_id', video_id); 
  data.append('image', newSceneData.image);


  const queryParams = new URLSearchParams({ userId });

  return postFormData(`scenes/create?${queryParams.toString()}`, data, { headers });
};

export const deleteScene = ({ scene_id, userId }) => {
  const headers = {
    Authorization: 'idToken',
    'Content-Type': 'application/json',
  };
  const queryParams = new URLSearchParams({ userId });

  return del(`scenes/${scene_id}?${queryParams.toString()}`,  { headers });
};



export const getSceneShareUrl = ({ videoID, videoTime }) => {
  const headers = {
    Authorization: 'idToken',
    'Content-Type': 'application/json',
  };
  const queryParams = new URLSearchParams({ videoID, videoTime });

  return get(`scenes/share?${queryParams}`,  { headers });
};


export const getSceneThumbnail = ({ videoID, videoTime }) => {
  const headers = {
    Authorization: 'idToken',
    'Content-Type': 'application/json',
  };
  const queryParams = new URLSearchParams({ videoID, videoTime });

  return get(`scenes/get_thumbnail?${queryParams}`,  { headers });
};


export const deleteVideo = ({video_id} ) => {
  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };
  return del(`videos/${video_id}`, { headers });
};



export const getUserProjects = ( user_id) => {

  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };

  return get(`users/${user_id}/projects/list`,{ headers });
};



export const getUserInvitations = ( user_id) => {

  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };

  return get(`users/${user_id}/invitations`,{ headers });
};


export const getVideoNarrations = (video_id) => {

  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };

  return get(`videos/${video_id}/narrations`,{ headers });
};

export const updateVideoNarrations = ({video_id, data, userId} ) => {
  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };
  const queryParams = new URLSearchParams({ userId });

  const urlWithQuery = `videos/${video_id}/narrations?${queryParams.toString()}`;

  return put(urlWithQuery, data, { headers });
};


export const getProject = ( user_id, project_id) => {

  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };

  return get(`users/${user_id}/projects/${project_id}`,{ headers });
};

export const getProjectSubmissions = ( user_id, project_id) => {

  const headers = {
    Authorization: "idToken",
    'Content-Type': 'application/json'
  };

  return get(`users/${user_id}/projects/${project_id}/videos/submissions`,{ headers });
};