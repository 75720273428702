import { css } from '@emotion/react';
import { Button, Form, Input, notification } from 'antd';
import { UserContext } from 'components';
import { useContext, useEffect, useState } from 'react';
import { StyledLabel } from '../Create/Inputs/FormLabel';
import Members from '../Create/Inputs/Members';
import { deleteProject, updateProject } from '../requests';
import { InvitationForm } from './InvitationForm';
import ProjectActions from './ProjectActions';


const formStyles = css`
  .ant-form-item-label {
    text-align: left;
    label {
      font-weight: bold;
    }
  }
`;

const inputStyle = css`
  max-width: 700px;
`;

const formActionsStyles = css`
  text-align: center;
  margin: 10px;
  width: 100px;
`;

const formContainerStyles = css`
  display: flex;
  margin: 10px;
  gap: 16px;
  flex-direction: row;
`;

const Edit = ({ project }) => {
  const [form] = Form.useForm();
  const [members, setMembers] = useState([]);
  const [companions, setCompanions] = useState([]);
  const [companion, setCompanion] = useState({ name: '', picture: '' });
  const [errors, setErrors] = useState([]);
  const { user } = useContext(UserContext);
  const allowEdit = project.role === 'admin';

  useEffect(() => {
    form.setFieldsValue({
      title: project.title
    });
    setMembers(project.members);
    setCompanions(project.companions);
  }, [form]);

  const handleFinish = async values => {
    try {
      const updatedProject = {
        ...values,
        title: values.title,
        companions: companions,
        members: members
      };
      const response = await updateProject({
        user: user,
        project_id: project.id,
        data: updatedProject
      });
      if (response.status === 200) {
        notification.success({
          message: 'Form submitted',
          description: 'The form was submitted successfully.'
        });
      } else {
        notification.error({
          message: 'Form submission failed',
          description: 'Failed to submit the form. Please try again.'
        });
      }
    } catch (error) {
      notification.error({
        message: 'Form submission failed',
        description: 'Failed to submit the form. Please try again.'
      });
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteProject({
        user: user,
        project_id: project.id
      });
      if (response.status === 200) {
        notification.success({
          message: 'Project deleted',
          description: 'The project was deleted successfully.'
        });
      } else {
        notification.error({
          message: 'Deletion failed',
          description: 'Failed to delete the project. Please try again.'
        });
      }
    } catch (error) {
      notification.error({
        message: 'Deletion failed',
        description: 'Failed to delete the project. Please try again.'
      });
    }
  };

  const handleMembersChange = newMembers => {
    setMembers(newMembers);
  };

  return (
    <div style={{ maxWidth: '1000px', margin: '20px' }}>
      <Form form={form} onFinish={handleFinish} css={formStyles}>
        <div css={formContainerStyles}>
          <StyledLabel text={'Title'} style={{ margin: 10 }} />
          <div>
            <Form.Item style={{ maxWidth: '200px' }} name="title">
              <Input className={inputStyle} />
            </Form.Item>
            <Members
              project={project}
              onChange={handleMembersChange}
              allowEdit={allowEdit}
              initialState={
                members.length > 0 ? members : [{ name: '', email: '' }]
              }
            />
          </div>

          <InvitationForm user={user} project={project} />
        </div>
        {errors.length > 0 && (
          <div className="notification is-danger">
            {errors.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </div>
        )}
        <Form.Item css={formActionsStyles}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: '8px', marginTop: '10px' }}>
            Save
          </Button>
          {allowEdit && (
            <Button danger onClick={handleDelete}>
              Delete
            </Button>
          )}
        </Form.Item>
      </Form>
      <ProjectActions user={user} project={project} />
    </div>
  );
};

export default Edit;
