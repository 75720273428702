import React, { useState } from 'react';
import { Button, Input, notification, Space, Modal } from 'antd';
import { uploadToPhotos } from '../requests';
import { ReactComponent as Photos } from './photos.svg';

export const UploadToPhotosForm = ({ user, project }) => {
  const [albumName, setAlbumName] = useState('Shoebox Videos');
  const [buttonText, setButtonText] = useState('Upload to Google Photos');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleUploadToPhotosSubmit = async () => {
    if (!albumName.trim()) {
      notification.warning({
        message: 'Album Name Required',
        description: 'Please enter an album name before uploading.'
      });
      return;
    }
    setIsModalVisible(true);
  };

  const confirmUpload = async () => {
    setIsModalVisible(false);
    setIsLoading(true);
    try {
      const response = await uploadToPhotos({
        user: user,
        project_id: project.id,
        data: {
          album_name: albumName, 
          all_videos: true,
        }
      });

      if (response.status === 200) {
        setUploadStatus('Upload successful!');
        notification.success({
          message: 'Upload Successful',
          description: 'Your photos have been uploaded to Google Photos.'
        });
        setAlbumName('');
      } else {
        notification.error({
          message: 'Upload Failed',
          description: 'Failed to upload photos. Please try again.'
        });
      }
    } catch (error) {
      notification.error({
        message: 'Upload Failed',
        description: 'Failed to upload photos. Please try again.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4" style={{ margin: '10px' }}>
      <Space direction="horizontal" className="w-full items-center">
        <Photos />
        <Input
          placeholder="Enter album name"
          value={albumName}
          onChange={e => setAlbumName(e.target.value)}
          className="max-w-md"
        />
        <Button
          type="primary"
          loading={isLoading}
          onClick={handleUploadToPhotosSubmit}
        >
          {buttonText}
        </Button>
        {uploadStatus && <span className="text-green-600">{uploadStatus}</span>}
      </Space>

      <Modal
        title="Confirm Upload"
        visible={isModalVisible}
        onOk={confirmUpload}
        onCancel={() => setIsModalVisible(false)}
        okText="Yes, Upload"
        cancelText="Cancel"
      >
        <p>Upload your shoebox videos to Google Photos?</p>
      </Modal>
    </div>
  );
};

export default UploadToPhotosForm;
