import React from 'react';
import { Card, Form, Input, Button, Typography, message } from 'antd';
import { ReactComponent as Tape } from '../Start/images/tape.svg';

const { Title } = Typography;

const Register = () => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    console.log('Submitted:', values);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_BACKEND_URL}/signup/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      });
      const data = await response.json();
      
      if (response.ok) {
        message.success('הרשמה בוצעה בהצלחה! ניצור איתך קשר בהקדם');
        form.resetFields(); 
      } else {
        message.error(data.message || 'שגיאה בהרשמה');
      }
    } catch (error) {
      message.error('שגיאת רשת, נסה שוב');
    }
  };

  return (
    <Card style={{ maxWidth: 400, margin: '0 auto', padding: 20, direction: 'rtl' }}>
      <Title level={3} style={{ textAlign: 'center' }}>הרשמה</Title>
      <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
          label="שם"
          name="name"
          rules={[{ required: true, message: 'נא להזין אימייל', type: 'string' }]}
        >
          <Input placeholder="דני הנוסטלגי" />
        </Form.Item>
        <Form.Item
          label="אימייל"
          name="email"
          rules={[{ required: true, message: 'נא להזין אימייל', type: 'email' }]}
        >
          <Input placeholder="example@email.com" />
        </Form.Item>
        
        <Form.Item
          label="טלפון"
          name="phone"
          rules={[{ required: true, message: 'נא להזין מספר טלפון' }]}
        >
          <Input placeholder="050-1234567" />
        </Form.Item>
        
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            הרשמה
          </Button>
        </Form.Item>
      </Form>
      <Tape />
    </Card>
  );
};

export default Register;
